import { createAsyncThunk } from '@reduxjs/toolkit';
import { TState } from 'store/index';
import {
  ExportPagesInterface,
  ImportPagesInterface,
  PageInterface,
  PagesActionsTypes,
  UpdatePageByIdPayload,
  UpdatePagePositionPayload,
} from './types';
import { exportPages, importPages, loadFirstPageId, loadPages, loadRLSUserAndGroup } from 'store/reducers/projectPages/api';
import { AxiosError } from 'axios';
import { serverErrorText } from 'constants/ServerCode';
import Snackbar from 'services/Snackbar';
import snackbar from 'services/Snackbar';
import { getActivePage, getFirstPageId, getPages } from 'store/reducers/projectPages/getters';
import { addNewPage, deletePage, setSlice, updatePages } from '.';
import { v4 } from 'uuid';
import { moveArrayItem, transformationFileForUpload } from 'utils/utils';
import { getWidgetsByPageId } from 'store/reducers/visualisations/getters';
import { getFiltersByPageId } from 'store/reducers/filters/getters';
import { addVisualisationByData } from 'store/reducers/visualisations/actions';
import { addFilterByDataAction } from 'store/reducers/filters/actions';
import { getInitialPages, initialPagesStoreState } from 'store/reducers/projectPages/constants';
import { getPageLayers } from 'store/reducers/board/getters';
import {
  addLayerByPageIdAction,
  addNewWidgetsFromBufferAction,
  deleteLayerByPageIdAction,
  removeBoardElementByIdAction,
  updatesLayersAction,
} from 'store/reducers/board/actions';
import { addToAlreadyLoadedByPageId as addToAlreadyLoadedByPageIdOfVisualisations } from 'store/reducers/visualisations';
import { addToAlreadyLoadedByPageId as addToAlreadyLoadedByPageIdOfFilter } from 'store/reducers/filters';
import { addToAlreadyLoadedByPageId as addToAlreadyLoadedByPageIdOfBoard } from 'store/reducers/board';
import { addToAlreadyLoadedByPageId as addToAlreadyLoadedByPageIdOfGroupsVisualisations } from 'store/reducers/groupsVisualisations';
import { SettingsSnapshotType } from 'store/reducers/projectSettings/settingsSnapshotService';
import { exportJsonFile } from 'modules/workspace/components/panelSettingsApp/tabsContent/PalettesTab/PaletteColorSettings/constants';
import isNull from 'lodash/isNull';
import { getWidgetsGroupsByPageId } from '../groupsVisualisations/getters';

const validateError = (err: AxiosError, rejectWithValue: any) => {
  const error: AxiosError = err;
  if (!error.response) {
    throw err;
  }

  const errorCode = error.response.status;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const errorMessage: string = error?.response?.data?.message || serverErrorText[errorCode];
  Snackbar.show(errorMessage, 'error');
  return rejectWithValue(errorMessage);
};

export const addPageAction = createAsyncThunk(PagesActionsTypes.ADD_PAGE, (_, { dispatch }) => {
  const page = getInitialPages({ id: v4() });

  dispatch(addPageByDataAction(page));
  dispatch(addLayerByPageIdAction(page.id));
});

export const updatePageByIdAction = createAsyncThunk<void, UpdatePageByIdPayload>(
  PagesActionsTypes.UPDATE_PAGE_BY_ID,
  ({ id, page }, { dispatch, getState }) => {
    const pages = getPages(getState() as TState).map((value) => (value.id === id ? { ...value, ...page } : value));

    dispatch(updatePages(pages));
  },
);

export const updatePagePositionAction = createAsyncThunk<void, UpdatePagePositionPayload>(
  PagesActionsTypes.UPLOAD_PAGES_POSITION,
  ({ id, moveTo }, { dispatch, getState }) => {
    const pages = getPages(getState() as TState);

    const indexOfPages = pages.findIndex(({ id: pageId }) => pageId === id),
      { newArray } = moveArrayItem(pages, indexOfPages, moveTo);

    dispatch(updatePages(newArray));
  },
);

export const deletePageByIdAction = createAsyncThunk<void, string>(
  PagesActionsTypes.DELETE_PAGE,
  (pageId, { dispatch, getState }) => {
    const state = getState() as TState,
      visualisations = getWidgetsByPageId(pageId)(state),
      filters = getFiltersByPageId(pageId)(state);

    dispatch(deletePage(pageId));
    dispatch(deleteLayerByPageIdAction(pageId));

    [...visualisations, ...filters].forEach((widget) => {
      if (widget.pageId === pageId) {
        dispatch(removeBoardElementByIdAction(widget.id));
      }
    });
  },
);

export const addPageByDataAction = createAsyncThunk(PagesActionsTypes.ADD_PAGE_BY_DATA, (data: PageInterface, { dispatch }) => {
  const { id } = data;

  dispatch(addNewPage(data));

  dispatch(addToAlreadyLoadedByPageIdOfVisualisations(id));
  dispatch(addToAlreadyLoadedByPageIdOfFilter(id));
  dispatch(addToAlreadyLoadedByPageIdOfBoard(id));
  dispatch(addToAlreadyLoadedByPageIdOfGroupsVisualisations(id));
});

export const copyPageAction = createAsyncThunk<void, { page?: PageInterface }>(
  PagesActionsTypes.COPY_PAGE,
  ({ page }, { dispatch, getState }) => {
    const state = getState() as TState;
    const activePage = getActivePage(state);

    const pageVariant = page || activePage;

    if (pageVariant) {
      const visualisations = getWidgetsByPageId(pageVariant?.id)(state),
        filters = getFiltersByPageId(pageVariant?.id)(state),
        groups = getWidgetsGroupsByPageId(pageVariant?.id)(state),
        newPage: PageInterface = { ...pageVariant, id: v4(), name: pageVariant.name + ' - Копия' },
        pageId = newPage.id,
        layers = getPageLayers(state) || [];

      let newLayers = [...layers];

      dispatch(addPageByDataAction(newPage));

      visualisations.forEach((visualisation) => {
        const newVisualisation = {
          ...visualisation,
          pageId,
          id: v4(),
        };
        newLayers = newLayers.map((layerId) => (layerId === visualisation.id ? newVisualisation.id : layerId));
        dispatch(addVisualisationByData(newVisualisation));
      });

      filters.forEach((filter) => {
        const newFilter = {
          ...filter,
          pageId,
          id: v4(),
        };
        newLayers = newLayers.map((layerId) => (layerId === filter.id ? newFilter.id : layerId));
        dispatch(addFilterByDataAction(newFilter));
      });

      groups.forEach((group) => {
        const id = v4();

        const newGroup = {
          ...group,
          pageId,
          id,
        };
        newLayers = newLayers.map((layerId) => (layerId === group.id ? newGroup.id : layerId));
        dispatch(
          addNewWidgetsFromBufferAction({
            id,
            bufferItem: group,
            activePageId: pageId,
          }),
        );
      });

      dispatch(updatesLayersAction({ pageId, layers: newLayers }));
    }
  },
);

export const loadPagesAction = createAsyncThunk<PageInterface[], string>(
  PagesActionsTypes.LOAD_PAGES,
  async (project_id: string, { rejectWithValue }) => {
    try {
      const response = await loadPages(project_id);
      return response.data.projectPages;
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return [] as PageInterface[];
    }
  },
);

export const loadRLSUserAndGroupAction = createAsyncThunk<FastBoard.API.UserGroupListItemResponseDTO[], string>(
  PagesActionsTypes.LOAD_RLS_USER_AND_GROUP,
  async (project_id: string, { rejectWithValue }) => {
    try {
      const response = await loadRLSUserAndGroup(project_id);
      return response.data.userGroupList;
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return [] as FastBoard.API.UserGroupListItemResponseDTO[];
    }
  },
);

export const loadPagesFromSnapshotAction = createAsyncThunk<PageInterface[], SettingsSnapshotType['pages']>(
  PagesActionsTypes.LOAD_PAGES_FROM_SNAPSHOT,
  (pages) => pages,
);

export const loadFirstPageByProjectIdAction = createAsyncThunk(
  PagesActionsTypes.LOAD_FIRST_BY_PAGE_ID,
  async (project_id: string, { dispatch, getState }) => {
    const firstPageId = getFirstPageId(getState() as TState);

    if (isNull(firstPageId)) {
      dispatch(loadFirstPageIdAction(project_id));
    }
  },
);

export const loadFirstPageIdAction = createAsyncThunk<string, string>(
  PagesActionsTypes.LOAD_FIRST_PAGE_ID,
  async (project_id: string, { rejectWithValue }) => {
    try {
      const response = await loadFirstPageId(project_id);

      return response.data.firstPageId;
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return '';
    }
  },
);

export const ExportPagesAction = createAsyncThunk<void, ExportPagesInterface>(
  PagesActionsTypes.EXPORT_PAGES_ACTION,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await exportPages(params);

      exportJsonFile(JSON.stringify(data), 'pages');
    } catch (err: any) {
      validateError(err, rejectWithValue);
    }
  },
);

export const ImportPagesAction = createAsyncThunk<unknown, ImportPagesInterface<File>>(
  PagesActionsTypes.IMPORT_PAGES_ACTION,
  async (params, { rejectWithValue }) => {
    try {
      const fileForImport = transformationFileForUpload({ name: 'file', file: params.file });
      const response = await importPages({ ...params, file: fileForImport });

      if (response) {
        snackbar.show('Проект успешно импортирован', 'success');
      }

      return response.data;
    } catch (err: any) {
      validateError(err, rejectWithValue);
    }
  },
);

export const clearProjectPagesStore = createAsyncThunk(PagesActionsTypes.CLEAR_PROJECT_PAGES_STORE, (_, { dispatch }) => {
  dispatch(setSlice(initialPagesStoreState));
});
