import { hsl, interpolateRgb, scaleLinear } from 'd3';
import domtoimage, { Options } from 'dom-to-image';
import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import isString from 'lodash/isString';
import isNumber from 'lodash/isNumber';
import {
  TextVariablesEnum,
  TextVariablesType,
  TransformationTextVariablesElementInterface,
} from 'modules/visualisations/Text/visualisation/types';
import { TAG_INPUT, TAG_TEXTAREA } from 'modules/workspace/constans';
import {
  BackgroundSettingsInterface,
  BorderSpecificValueEnum,
  ColorByRuleInterface,
  ColorOperatorEnum,
  ConditionsMarkerType,
  DefaultVisualisationOptionsType,
  ElementSpecificValueEnum,
  GetSpecificGradientInterpolatorParams,
  SortOrderEnum,
  SpecificColorByItem,
  TableDataSettings,
  TableIncisionInterface,
  TableIndicatorInterface,
  TextVariablesInterface,
  VisualisationOperationTypesEnum,
} from 'store/reducers/visualisations/types';
import { BoardPositionConfigInterface, HlsColorInterface, IdInterface, PositionConfigSettingsInterface } from 'types/store';
import { MapRecordType, NoopType } from 'types/global';
import { defaultSelectAST, generateILike, getWhereString, sqlParser } from './SQL/genereteAst';
import { AST } from 'types/ast';
import { generateSqlFullQuery } from './SQL/generateSQL';
import { combineSqlStrings } from './SQL/formatSQL';
import { Column, Select } from 'node-sql-parser';
import { FilterDataType, GenerateFilterSqlStringInterface } from 'store/reducers/filters/types';
import { SortingValueEnum } from 'components/shared/SortingPanel/types';
import { ModelFromMetaType } from 'store/reducers/models/types';
import {
  convertHexToHslResultInteface,
  OperationTypeEnum,
  RuleEnum,
  VariantsFontsProjectSettingsInterface,
  WidgetRuNameEnum,
} from 'types/types';
import { IncisionsAstType } from 'store/reducers/ast/types';
import { initialPositionConfig } from 'store/reducers/visualisations/constants';
import { CursorType } from 'types/styles';
import { MIN_SIZE_VISUALISATION } from 'constants/global';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import {
  DATE_OPERATION_TYPES,
  MOCK_GRAPHIC_OPERATION_TYPE_RULES,
  NUMBER_OPERATION_TYPES,
  STRING_OPERATION_TYPES,
} from 'constants/Mock';
import { ColorValuesByThemeType } from 'modules/settingsContainer/ColorPicker/types';
import { useRef } from 'react';
import { GroupWidgetSettingsInterface } from 'store/reducers/groupsVisualisations/types';
import { parseCustomAliasName } from './formatting';
import { trackerSection } from 'modules/workspace/components/WorkAreaSpace/constants';
import { PlaceholderOptions } from 'modules/settingsContainer/common/ColorBySpecificValue/types';

export const getTimeFromTimestamp = (timestamp: number) => {
  const date = new Date(timestamp);

  return (
    ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2) + ':' + ('0' + date.getSeconds()).slice(-2)
  );
};

export type MoveToType = 'up' | 'down' | 'top' | 'bottom';

export interface InvalidIndicesResult {
  indices: number[];
  errors: Record<number, string>;
}

export enum MoveToEnum {
  UP = 'up',
  DOWN = 'down',
  TOP = 'top',
  BOTTOM = 'bottom',
}

export const moveArrayItem = <T>(array: T[], index: number, moveTo: MoveToType) => {
  const newArray = [...array];
  let newIndex: number | null = null;

  if (moveTo === MoveToEnum.UP && index > 0) {
    newIndex = index - 1;
    const temp = newArray[index];
    newArray[index] = newArray[newIndex];
    newArray[newIndex] = temp;
  }

  if (moveTo === MoveToEnum.DOWN && index < array.length - 1) {
    newIndex = index + 1;
    const temp = newArray[index];
    newArray[index] = newArray[newIndex];
    newArray[newIndex] = temp;
  }

  if (moveTo === MoveToEnum.TOP && index > 0) {
    newIndex = 0;
    const [removed] = newArray.splice(index, 1);
    newArray.splice(newIndex, 0, removed);
  }

  if (moveTo === MoveToEnum.BOTTOM && index < array.length - 1) {
    newIndex = array.length - 1;
    const [removed] = newArray.splice(index, 1);
    newArray.splice(newIndex, 0, removed);
  }

  return { newArray, oldIndex: index, newIndex };
};

export const getMaximumStringLengthOfArrayData = (array?: Array<string | null> | Array<number | null>) =>
  (array as Array<string | number>)?.reduce<string | number>(
    (max, current) => (String(current).length > String(max).length ? current : max),
    '',
  ) || '';

interface GetGradientInterpolatorParams {
  colors: string[];
  maxValue?: number;
  minValue?: number;
}

interface GradientInterpolatorParams {
  value: number;
  colors: SpecificColorByItem[];
  valueType: ElementSpecificValueEnum;
  minValue: number;
  maxValue: number;
}

interface CalculateDomainParams {
  rawData: SpecificColorByItem[];
  minValue: number;
  maxValue: number;
  valueType?: ElementSpecificValueEnum;
  borderType?: BorderSpecificValueEnum;
}

/**
 * Возвращает цвет из массива `colors`, чей числовой показатель `numericalValue` ближе всего к аргументу `value`.
 * - Если `type` = 'min' или 'max', значение ставится равным `minValue` / `maxValue`.
 * - Если `valueType` = PERCENT, числовое значение пересчитывается относительно `[minValue..maxValue]` в проценты.
 * - Возвращает `null`, если массив пустой или некорректен.
 * Функция рассчитывает цвет градиента для режима По значению
 */
export const customGradientInterpolator = ({ value, colors, valueType, minValue, maxValue }: GradientInterpolatorParams) => {
  if (!colors || !!colors.length) {
    return null;
  }

  const parsedColors = colors.map((color) => {
    let numericalValue = color.numericalValue;

    if (color.type === 'min') {
      numericalValue = minValue;
    }
    if (color.type === 'max') {
      numericalValue = maxValue;
    }

    if (valueType === ElementSpecificValueEnum.PERCENT && !isNull(numericalValue) && !isString(numericalValue)) {
      numericalValue = ((numericalValue - minValue) / (maxValue - minValue)) * 100;
    }

    return { ...color, numericalValue };
  });

  const closestColor = parsedColors.reduce<SpecificColorByItem | null>((closest, current) => {
    if (
      closest?.numericalValue &&
      current.numericalValue !== null &&
      !isString(current.numericalValue) &&
      !isString(closest.numericalValue) &&
      (closest === null || Math.abs(current.numericalValue - value) < Math.abs(closest.numericalValue - value))
    ) {
      return current;
    }
    return closest;
  }, null);

  return closestColor?.value || null;
};

export const calculateDomain = ({ rawData, minValue, maxValue, valueType, borderType }: CalculateDomainParams): number[] => {
  if (rawData.length === 1) {
    return [minValue, maxValue];
  }

  if (borderType === BorderSpecificValueEnum.AUTOMATICALLY && valueType === ElementSpecificValueEnum.ABSOLUTE) {
    return rawData.map((_, index) => minValue + ((maxValue - minValue) / (rawData.length - 1)) * index);
  }

  if (borderType === BorderSpecificValueEnum.MANUALLY && valueType === ElementSpecificValueEnum.ABSOLUTE) {
    return rawData.map((item, index) => {
      const val = Number(item.numericalValue);
      if (!isNaN(val)) return val;

      if (index === 0) return minValue;
      if (index === rawData.length - 1) return maxValue;

      return minValue + ((maxValue - minValue) / (rawData.length - 1)) * index;
    });
  }

  if (
    (borderType === BorderSpecificValueEnum.MANUALLY && valueType === ElementSpecificValueEnum.PERCENT) ||
    (borderType === BorderSpecificValueEnum.AUTOMATICALLY && valueType === ElementSpecificValueEnum.PERCENT)
  ) {
    return rawData.map((item, index) => {
      const perc = Number(item.numericalValue);

      if (index === 0) return minValue;
      if (index === rawData.length - 1) return maxValue;

      if (!isNaN(perc)) {
        const fraction = perc / 100;
        return minValue + fraction * (maxValue - minValue);
      }

      const fraction = index / (rawData.length - 1);
      return minValue + fraction * (maxValue - minValue);
    });
  }

  return rawData.map((_, index) => minValue + ((maxValue - minValue) / (rawData.length - 1)) * index);
};

export const getSpecificGradientInterpolator = ({
  actualColors,
  rawData,
  minValue = 0,
  maxValue = 1,
  valueType,
  borderType,
}: GetSpecificGradientInterpolatorParams) => {
  if (!actualColors.length) {
    return () => null;
  }

  const domain = calculateDomain({ rawData, minValue, maxValue, valueType, borderType });
  const range = [...actualColors];

  if (minValue < Math.min(...domain)) {
    domain.unshift(minValue);
    range.unshift(actualColors[0]);
  }
  if (maxValue > Math.max(...domain)) {
    domain.push(maxValue);
    range.push(actualColors[actualColors.length - 1]);
  }

  return scaleLinear<string>().domain(domain).range(range).interpolate(interpolateRgb).clamp(true);
};

export const getGradientInterpolator = ({ colors, minValue = 0, maxValue = 1 }: GetGradientInterpolatorParams) => {
  if (!colors.length) {
    return () => null;
  }

  let domain: number[];

  if (colors.length === 1) {
    domain = [minValue, maxValue];
  } else {
    domain = colors.map((_, index) => ((maxValue - minValue) / (colors.length - 1)) * index + minValue);
  }

  return scaleLinear<string>().domain(domain).range(colors).interpolate(interpolateRgb);
};

export type CreateGradientOrStripesFromColorsType =
  | undefined
  | string
  | { type: 'linear'; x: number; y: number; x2: number; y2: number; colorStops: { offset: number; color: string }[] };

export const createGradientOrStripesFromColors = (
  colors: string[],
  useStripes = false,
): CreateGradientOrStripesFromColorsType => {
  if (!colors || !colors.length) {
    return;
  }

  if (colors.length === 1) {
    return colors[0];
  }

  if (useStripes) {
    const colorStops: { offset: number; color: string }[] = [];
    const step = 1 / colors.length;
    colors.forEach((color, index) => {
      const offsetStart = index * step;
      const offsetEnd = (index + 1) * step;
      colorStops.push({ offset: offsetStart, color }, { offset: offsetEnd, color });
    });

    return {
      type: 'linear',
      x: 0,
      y: 0,
      x2: 1,
      y2: 0,
      colorStops,
    };
  } else {
    return {
      type: 'linear',
      x: 0,
      y: 0,
      x2: 1,
      y2: 0,
      colorStops: colors.map((color, index) => ({
        offset: index / (colors.length - 1),
        color,
      })),
    };
  }
};

export const getColorByValueMinMax = <ColorsType = unknown[]>({
  min,
  max,
  colors,
  value,
}: {
  min: number;
  max: number;
  colors: ColorsType[];
  value: number;
}): ColorsType => {
  if (value <= min || value <= 0) {
    return colors[0];
  }

  if (value >= max) {
    return colors[colors.length - 1];
  }

  const interval = (max - min) / colors.length,
    colorIndex = Math.floor((value - min) / interval);

  return colors[colorIndex];
};

export const isColor = (stringColor?: string | number | null) => {
  if (typeof stringColor === 'number' || !stringColor) return false;

  const styleOption = new Option().style;
  styleOption.color = stringColor;
  return styleOption.color == stringColor || !!styleOption.color.match('^rgb');
};

export const getMaxAndMinFromArray: (array?: Array<string | null> | Array<number | null> | null) => {
  max: number;
  min: number;
} = (array = []) =>
  (array as (string | number)[]).reduce(
    (result, value) => {
      const comparedValue = typeof value === 'string' ? 1 : value;

      const max = comparedValue > result.max ? comparedValue : result.max,
        min = comparedValue < result.min ? comparedValue : result.min;

      return { max, min };
    },
    { max: 0, min: 0 },
  ) || { max: 0, min: 0 };

export const getRandomArrayItem: <T>(array: T[]) => T | undefined = (array) => array[Math.floor(Math.random() * array.length)];

export const getArrayItemByCountlessIndex: <T>(array: T[], index: number) => T = (array, index) => {
  const arrayLength = array.length;
  let arrayIndex = index % arrayLength;

  if (index < 0) {
    arrayIndex = arrayLength + arrayIndex;
  }

  return array[arrayIndex];
};

export const getSumOfArrayValues: (array: Array<number | string | null | undefined>) => number = (array) =>
  array.reduce<number>((sum, value) => {
    const normalizedValue = typeof value === 'string' ? 0 : value || 0;

    return normalizedValue + sum;
  }, 0);

interface ObjectInterface {
  [key: string]:
    | boolean
    | string
    | null
    | number
    | undefined
    | Array<boolean | string | null | number | ObjectInterface>
    | ObjectInterface;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const deepMergeByReference = <T extends Object>(
  target: any,
  reference: T,
  options?: { keyChanger?: MapRecordType<() => any> },
) => {
  const keyChanger = options?.keyChanger || {};

  let result = {};

  Object.keys(reference).forEach((key) => {
    const referenceValue = (reference as any)[key],
      isReferenceArray = Array.isArray(referenceValue),
      keyChangerFunction = keyChanger[key],
      targetValue = target[key];

    let changedReferenceValue = referenceValue;

    /* Using key Changer Function - key from Function instead of reference value */
    if (typeof keyChangerFunction === 'function') {
      changedReferenceValue = keyChangerFunction();
    }

    /* No key in target */
    if (targetValue === undefined) {
      result = { ...result, [key]: changedReferenceValue };
      return;
    }

    /* Logic for array reference data */
    if (isReferenceArray) {
      /* Value of target is not array */
      if (!Array.isArray(targetValue)) {
        result = { ...result, [key]: referenceValue };
        return;
      }

      /* Value of target empty array */
      if (targetValue.length === 0) {
        result = { ...result, [key]: [] };
        return;
      }

      const firstArrayValue = referenceValue?.[0],
        typeOfArrayValue = typeof firstArrayValue;

      /* Structure of reference array values are null or undefined  */
      if (firstArrayValue === undefined || firstArrayValue === null) {
        result = { ...result, [key]: targetValue };
        return;
      }

      /* Structure of reference array values are string / number / boolean   */
      if (['string', 'boolean', 'number'].includes(typeOfArrayValue)) {
        const keyResult = targetValue.reduce<Array<ObjectInterface | string | boolean | number>>((targetArray, value) => {
          if (value !== null && typeof value === (typeOfArrayValue as 'string' | 'boolean' | 'number')) {
            return [...targetArray, value];
          }
          return targetArray;
        }, []);

        result = {
          ...result,
          [key]: keyResult,
        };
        return;
      }

      /* Structure of reference array values are Object */
      if (typeOfArrayValue === 'object') {
        const keyResult = targetValue.reduce<ObjectInterface[]>((resultArray, value) => {
          if (
            value === null ||
            typeof value === 'string' ||
            typeof value === 'number' ||
            typeof value === 'boolean' ||
            Array.isArray(value)
          ) {
            return resultArray;
          }

          const deep = deepMergeByReference(value, firstArrayValue as ObjectInterface, options);
          return [...resultArray, deep];
        }, []);

        result = { ...result, [key]: keyResult };
        return;
      }
    }

    /* Logic for Primitive (null / string / number / boolean) reference data */
    if (
      referenceValue === null ||
      typeof referenceValue === 'string' ||
      typeof referenceValue === 'number' ||
      typeof referenceValue === 'boolean'
    ) {
      if (typeof targetValue === typeof referenceValue || referenceValue === null) {
        result = { ...result, [key]: targetValue };
        return;
      }

      result = { ...result, [key]: changedReferenceValue };
      return;
    }

    /* Logic for Object reference data */
    if (typeof referenceValue === 'object') {
      /* If target Value are Primitive using Reference Value */
      if (
        targetValue === null ||
        typeof targetValue === 'string' ||
        typeof targetValue === 'number' ||
        typeof targetValue === 'boolean' ||
        Array.isArray(targetValue)
      ) {
        result = { ...result, [key]: changedReferenceValue };
        return;
      }

      const deepObject = deepMergeByReference(targetValue, referenceValue as ObjectInterface, options);

      result = { ...result, [key]: { ...deepObject } };
      return;
    }
  });

  return result as T;
};

export const mergeByReference = <OriginData extends Partial<IdInterface>, ReferenceData extends Partial<IdInterface>>({
  originData,
  typeKey,
  getReferenceByType,
}: {
  originData: OriginData[];
  typeKey: keyof OriginData;
  getReferenceByType: (type: string) => ReferenceData;
}) => {
  const mergedData = originData.reduce((result, originData) => {
    const type = originData[typeKey];
    const reference = type && getReferenceByType(type as string);

    const merged = reference ? deepMergeByReference(originData, reference) : undefined;

    if (merged && merged?.id) {
      return { ...result, [merged.id]: merged };
    }

    return { ...result };
  }, {} as Record<string, ReferenceData>);

  return mergedData || {};
};

export const mergeRightMapOfSetWithLeft = <T>(left: MapRecordType<Set<T>>, right: MapRecordType<Set<T>>) => {
  const merged = Object.keys(right).reduce<MapRecordType<Set<T>>>((result, key) => {
    const leftState = left[key] || new Set<T>(),
      rightState = right[key] || new Set<T>();

    return { ...result, [key]: new Set([...leftState, ...rightState]) };
  }, {});

  return { ...left, ...merged };
};

export const isField = (eventTagName: string) => [TAG_INPUT, TAG_TEXTAREA].includes(eventTagName);

export const isHasParentWithClassName = (target: Element, className: string): boolean => {
  if (target.classList.contains(className)) {
    return true;
  }

  if (!target.parentElement) {
    return false;
  }

  return isHasParentWithClassName(target.parentElement, className);
};

export const findNextIndex = (currentNames: string[], pattern: string) => {
  const indexTable: number[] = [];
  currentNames.forEach((name) => {
    const mayBeIndex = name.replace(pattern, ''),
      mayBeNumberIndex = Number(mayBeIndex);
    if (!isNaN(mayBeNumberIndex)) {
      indexTable[mayBeNumberIndex] = mayBeNumberIndex;
    }
  });

  const index = indexTable.findIndex((value) => value === undefined);

  return index === -1 ? currentNames.length : index;
};

export const getRandomInt = (min: any, max: any) => {
  const range = max - min + 1;
  return Math.round(Math.floor(Math.random() * range) + min);
};

export interface CheckInRangeInterface {
  value: number;
  min: number;
  max: number;
  minCondition: ConditionsMarkerType;
  maxCondition: ConditionsMarkerType;
}

export const checkInRange = ({ value, min, max, minCondition, maxCondition }: CheckInRangeInterface): boolean => {
  const conditions: Record<ConditionsMarkerType, (a: number, b: number) => boolean> = {
    more: (a, b) => a > b,
    moreOrEqual: (a, b) => a >= b,
    equal: (a, b) => a === b,
    lessOrEqual: (a, b) => a <= b,
    less: (a, b) => a < b,
  };

  return conditions[minCondition](value, min) && conditions[maxCondition](value, max);
};

interface LinkInterface {
  type: 'link';
  link: string;
  description: string;
}

interface TextInterface {
  type: 'text';
  text: string;
}

export const findLinksAndText = (str?: string): Array<LinkInterface | TextInterface> => {
  if (!str) {
    return [];
  }

  const regex =
    /#([a-zA-Zа-яА-Я\ 0-9\-\_]+)\[([(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:\/?#[\]\%@!\$&'\(\)\*\+,;=.\{\}]+)\]/g;
  const matches = [...str.matchAll(regex)];

  const results = [];
  let lastIndex = 0;

  for (const match of matches) {
    const [fullMatch, description, link] = match;

    const startIndex = str.indexOf(fullMatch, lastIndex);
    const endIndex = startIndex + fullMatch.length;
    const text = str.slice(lastIndex, startIndex);

    if (text.length > 0) {
      results.push({ type: 'text', text } as TextInterface);
    }

    results.push({ type: 'link', link, description } as LinkInterface);

    lastIndex = endIndex;
  }

  if (lastIndex < str.length) {
    const text = str.slice(lastIndex);
    results.push({ type: 'text', text } as TextInterface);
  }

  return results;
};

export const addProtocolToLink = (link: string) => {
  if (!link) {
    return '';
  }

  link = link.trim().replace(/ /g, '%20');

  if (!/^(ftp|http[s]?):\/\//.test(link)) {
    link = 'http://' + link;
  }

  return link;
};
export const isURL = (str: string | number | null): boolean => {
  if (typeof str !== 'string' || !str) return false;

  const regex = /((?:(?:http?|ftp)[s]*:\/\/)?[a-z0-9-%\/\&=?\.]+\.[a-z]{2,4}\/?([^\s<>\#%"\,\{\}\\|\\\^\[\]`]+)?)/gi;
  return regex.test(str);
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const getMapObject = <T extends Object>(array: T[] | undefined, key: keyof T) =>
  (array || []).reduce<Record<string, T>>((result, data) => ({ ...result, [data[key] as string]: data }), {});

export const getLastPath = (pathname: string) => {
  const slicedPath = pathname.split('/');

  return slicedPath[slicedPath.length - 1];
};

export const getValueByKeys = <T>(targetObject: MapRecordType<T>, keys: string[]) => {
  const key = keys.find((key) => !!targetObject[key]) || '';

  return targetObject[key];
};

export const isPositiveNumber = (number: number) => number >= 0;

export const createLinearScale = (domain: number[], range: number[]) =>
  scaleLinear().domain(domain).range(range) as (value: number) => number;

export const isEqualProps = <Props>(prevProps: Readonly<Props>, nextProps: Readonly<Props>) => isEqual(prevProps, nextProps);

export const getSomeArrays = <T>(currentArray: T[], targetArray: T[], pieceLength: number) => {
  const start = currentArray.length,
    end = start + pieceLength,
    endLength = end > targetArray.length ? targetArray.length : end;

  return [...currentArray, ...targetArray.slice(start, endLength)];
};

export const sortByYCoordinateFn = <T extends PositionConfigSettingsInterface>(a: T, b: T) =>
  a.positionConfig.y - b.positionConfig.y;

export const getFileExtension = (name: string): string => {
  if (!name) {
    return 'auto';
  }
  const dotIndex = name.lastIndexOf('.');

  if (dotIndex > 0) {
    return name.substring(dotIndex + 1) === 'xls' ? 'xlsx' : name.substring(dotIndex + 1);
  }

  return 'none';
};

export type FindVariablesAndLinks = {
  type: TextVariablesType;
  text: string;
  description?: string;
  origin?: string;
};

export const findVariablesAndLinks = (str?: string): FindVariablesAndLinks[] => {
  if (!str) {
    return [];
  }

  const regex =
    /\{\{([^}]+)\}\}|#([a-zA-Zа-яА-Я 0-9\-_]+)\[((?:https?:\/\/)?[a-zA-Zа-яА-Я0-9.-]+(?:\.[a-zA-Zа-яА-Я0-9.-]+)*[a-zA-Zа-яА-Я0-9\-._~:\/?%#[\]@!\$&'()*+,;=.\{\}]+|\{\{([^}]+)\}\})\]/g;

  const results: FindVariablesAndLinks[] = [];
  let lastIndex = 0;

  str.replace(regex, (match, variable, description, link, linkVariable, index) => {
    if (index > lastIndex) {
      results.push({ type: 'text', text: str.slice(lastIndex, index) });
    }

    if (variable) {
      results.push({ type: 'variable', text: variable, origin: match });
    } else if (linkVariable) {
      results.push({ type: 'linkVariables', text: linkVariable, description });
    } else if (link && description) {
      results.push({ type: 'link', text: link, description });
    }

    lastIndex = index + match.length;
    return match;
  });

  if (lastIndex < str.length) {
    results.push({ type: 'text', text: str.slice(lastIndex) });
  }

  return results;
};

export const isArrayEquals = <T, K>(a: T[], b: K[]) => {
  const diffAAndB = differenceWith(a, b, isEqual);
  const diffBAndA = differenceWith(b, a, isEqual);

  return diffAAndB.length === 0 && diffBAndA.length === 0;
};

export const replaceValueIfPresent = (text: string, newValue: string): string => {
  return text.includes('value') ? text.replace(/value/g, newValue) : text;
};

export const daysUntilDate = (targetDate: Date): string => {
  const currentDate = new Date();

  const timeDiff = targetDate.getTime() - currentDate.getTime();

  return String(Math.ceil(timeDiff / (1000 * 3600 * 24)));
};

export const calculateUsagePercentage = (used?: number, total?: number): number => {
  if (!used || !total) {
    return 0;
  }

  if (total === 0) {
    return 0;
  }
  return (used / total) * 100;
};

export const dataURLtoFile = (dataUrl: string, filename: string) => {
  const arr = dataUrl.split(',');
  const mime = (arr?.[0] || '').match(/:(.*?);/)?.[1];
  const bstr = atob(arr[arr.length - 1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const getNodeScreenFile = async (selector: string, name: string, options?: Options): Promise<File | null> => {
  try {
    const workspace = document.querySelector(selector);

    if (!workspace) return null;
    const imageInb64 = await domtoimage.toPng(workspace, options);
    return dataURLtoFile(imageInb64, name);
  } catch (error) {
    /*TODO тк ловится ошибка, скриншот работает некорректно*/
    return null;
  }
};

export const handleInfluenceStatus = (object: MapRecordType<boolean> | null, key: string) => {
  if (!object) {
    return true;
  }

  if (object?.hasOwnProperty(key)) {
    return object[key];
  } else {
    return true;
  }
};

export const applyToAllInfluences = (data: any, value: boolean, activeId: string) => {
  const filteredData: MapRecordType<boolean> = {};
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      if (key === activeId) {
        continue;
      }
      filteredData[key] = value;
    }
  }
  return filteredData;
};

export const truncateFilename = (filename: string, maxLength = 25): string => {
  const extensionIndex = filename.lastIndexOf('.');
  const namePart = filename.substring(0, extensionIndex);
  const extensionPart = filename.substring(extensionIndex);

  if (filename.length <= maxLength) {
    return filename;
  }

  const maxNameLength = maxLength - extensionPart.length;
  if (maxNameLength < 5) {
    return filename;
  }

  const prefixLength = Math.floor(maxNameLength / 2);
  const suffixLength = maxNameLength - prefixLength - 3;
  return namePart.substring(0, prefixLength) + '...' + namePart.substring(namePart.length - suffixLength) + extensionPart;
};

export const isSafari = () => {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  return isSafari;
};

export const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?$/i.exec(hex);

  return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)].join(', ') : null;
};

export const hasName = <T extends { name: string }>(list: T[], name: string): boolean => list.some((obj) => obj.name === name);

/* TODO: Remove any and write a normal type */
export const replaceEmptyValues = (values: any, emptyValue: string) => {
  if (Array.isArray(values)) {
    return values.map((val) => (isNull(val) || isUndefined(val) ? emptyValue : val));
  } else {
    return isNull(values) || isUndefined(values) ? emptyValue : values;
  }
};

export const replaceEmptyValuesForTableData = (
  dataSettings: TableDataSettings,
  data: Record<string, (string | null)[] | (number | null)[] | undefined>,
) => {
  const emptyValuesMap: Record<string, string | number> = {};

  const processArrayWithSettings = (array: TableIncisionInterface[]) => {
    array.forEach((item) => {
      const {
        name,
        fieldName,
        settings: {
          emptyValues: { isEmptyValue, value },
          nameFromDatabase,
        },
      } = item;

      /* TODO: Fix - try to use getVisualisationFieldName func and check all tests */
      const nameKey = nameFromDatabase ? fieldName || '' : name;

      if (isEmptyValue) {
        emptyValuesMap[nameKey] = value;
      }
    });
  };

  const processArrayWithoutSettings = (array: TableIndicatorInterface[]) => {
    array.forEach((item) => {
      const {
        name,
        fieldName,
        emptyValues: { isEmptyValue, value },
        settings: { nameFromDatabase },
      } = item;

      /* TODO: Fix - try to use getVisualisationFieldName func and check all tests */
      const nameKey = nameFromDatabase ? fieldName || '' : name;

      if (isEmptyValue) {
        emptyValuesMap[nameKey] = value;
      }
    });
  };

  processArrayWithSettings(dataSettings.incisionsInHeader);
  processArrayWithSettings(dataSettings.incisions);
  processArrayWithoutSettings(dataSettings.indicators);

  const updatedData: { [x: string]: (string | null)[] | (number | null)[] | undefined } = { ...data };
  Object.keys(updatedData).forEach((key) => {
    const emptyValue = emptyValuesMap[key];
    if (!isUndefined(emptyValue)) {
      const value = updatedData[key];
      if (Array.isArray(value)) {
        if (isString(emptyValue)) {
          updatedData[key] = value.map((item) => (isNull(item) ? emptyValue : item)) as (string | null)[];
        } else if (isNumber(emptyValue)) {
          updatedData[key] = value.map((item) => (isNull(item) ? emptyValue : item)) as (number | null)[];
        }
      }
    }
  });

  return updatedData;
};

export const replaceUndefinedWithEmptyValues = (
  textObjects: (TransformationTextVariablesElementInterface | null)[],
  variables: TextVariablesInterface[],
) => {
  return textObjects.map((obj) => {
    if (
      obj?.type === TextVariablesEnum.VARIABLE &&
      obj.origin &&
      (isUndefined(obj?.text) || isNull(obj?.text) || obj?.text === 'undefined')
    ) {
      const variableNames = obj.origin.match(/{{\s*[^}]+\s*}}/g);

      if (variableNames) {
        let newText = obj.origin;

        variableNames.forEach((variableWithBraces) => {
          const variableName = variableWithBraces.replace('{{', '').replace('}}', '').trim();
          const variable = variables.find((varObj) => varObj.name === variableName);

          if (variable && variable.emptyValues?.isEmptyValue) {
            newText = newText.replace(variableWithBraces, variable.emptyValues.value);
          } else {
            newText = newText.replace(variableWithBraces, obj?.text);
          }
        });

        obj.text = newText;
      }
    }
    return obj;
  });
};

export const generateFilterSqlString = ({
  sqlData,
  whereQuery,
  searchString,
  limit,
  sortingStatus,
  fromQuery,
  fieldName,
  isDataFilter,
  withoutValueAndCount,
  originLimit,
  backgroundSettings,
}: GenerateFilterSqlStringInterface) => {
  const { incisionRequest } = sqlData;
  const whereString = whereQuery && whereQuery !== '' ? whereQuery : '',
    { where } = sqlParser.astify(`SELECT * ${whereString}`) as Select;

  const colorByConditionSql = backgroundSettings?.colorSettings.backgroundColorBy.byCondition.sqlCondition;

  const filterString = searchString && searchString !== '' ? `%${searchString.toLowerCase()}%` : null;

  const sqlLimit = limit?.isActive ? limit?.value : '2000';
  // Запрашиваем на 1 больше лимита, что бы знать количество в базе больше чем лимит или нет
  const finalLimit = originLimit ? Number(sqlLimit) : Number(sqlLimit) + 1;

  let unionWhere: AST.UnionAndExpressions | AST.FunctionType | null = null;

  if (filterString) {
    unionWhere = generateILike({ fieldName: 'value', filterString });
  }

  if (where) {
    unionWhere =
      unionWhere !== null
        ? {
            type: 'binary_expr',
            left: where,
            operator: 'AND',
            right: unionWhere,
          }
        : where;
  }

  const unionWhereQuery = unionWhere !== null ? getWhereString(unionWhere) : undefined;

  const sortOrder = sortingStatus ? sortingStatus : SortingValueEnum.ASC;

  const finalFieldName = incisionRequest || fieldName;

  const sql =
    generateSqlFullQuery({
      selectQuery: `SELECT ${finalFieldName} ${!withoutValueAndCount ? 'as value, COUNT(*) as count' : ''} ${
        colorByConditionSql ? `, ${`${colorByConditionSql} as backgroundConditionValue`}` : ''
      }`,
      fromQuery,
      orderByQuery: `ORDER BY ${finalFieldName} ${sortOrder}`,
      whereQuery: unionWhereQuery,
      groupByQuery: `GROUP BY ${finalFieldName}`,
      limitQuery: `LIMIT ${finalLimit}`,
    }) || '';

  const selectQueryDataFilter = `SELECT '"' || toString(toDate(Min(${fieldName}))) || '", "' || toString(toDate(Max(${fieldName}))) || '"' ${
    !withoutValueAndCount ? 'as value' : ''
  }`;

  const sqlDataFilter =
    generateSqlFullQuery({
      selectQuery: selectQueryDataFilter,
      fromQuery,
      whereQuery: unionWhereQuery,
    }) || '';

  const finalSql = combineSqlStrings(isDataFilter ? sqlDataFilter : sql, sqlData, sortingStatus, isDataFilter);

  return finalSql;
};

export const findFirstFieldNameInRequest = (modelMetaData: ModelFromMetaType, request?: string | null) => {
  if (request) {
    const alias = modelMetaData.alias;
    const columns = modelMetaData.columns;

    for (const column of columns) {
      const columnName = column.name;
      const variable = `${alias}.${columnName}`;
      if (request.includes(variable)) {
        return variable;
      }
    }
  }
  return null;
};

export const generateWidgetName = (
  widgets: (FilterDataType | DefaultVisualisationOptionsType | GroupWidgetSettingsInterface)[],
  targetType: string,
  name?: string,
  isCopy?: boolean,
): string => {
  const ruWidgetName = name || WidgetRuNameEnum[targetType as keyof typeof WidgetRuNameEnum];

  const count = widgets.reduce((acc, widget) => {
    const type =
      (widget as DefaultVisualisationOptionsType).visualisationType ||
      (widget as FilterDataType | GroupWidgetSettingsInterface).type;

    return type === targetType ? acc + 1 : acc;
  }, 0);

  const currentCount = count + 1;

  const resultCount = isCopy ? `(${currentCount})` : currentCount;

  return `${ruWidgetName} ${resultCount}`;
};

const parseSqlStringForIncisionsInFilter = (inputString: string) => {
  if (!inputString) return null;

  const selectIndex = inputString.toLowerCase().indexOf('select ');
  const asIndex = inputString.toLowerCase().indexOf(' as ', selectIndex);

  if (selectIndex === -1) return inputString;

  const selectSubstring =
    asIndex !== -1 ? inputString.substring(selectIndex + 7, asIndex) : inputString.substring(selectIndex + 7);

  return selectSubstring.trim();
};

export const findNextIndexForColorGroup = (names: string[], defaultGroupName: string) => {
  const indices = names
    .map((name) => {
      const match = name.match(new RegExp(`^${defaultGroupName}(\\d+)$`));
      return match ? parseInt(match[1], 10) : null;
    })
    .filter((index) => index !== null)
    .sort((a, b) => a! - b!);

  let nextIndex = 1;

  for (const index of indices) {
    if (index !== nextIndex) {
      break;
    }
    nextIndex++;
  }

  return nextIndex;
};

export const getSqlStringIncisionsForEnabledFilter = (data: {
  incisions: IncisionsAstType[];
  activeIncisionIndex: number | null;
}) => {
  const { incisions, activeIncisionIndex } = data;
  const columns = activeIncisionIndex !== null ? [incisions[activeIncisionIndex]] : incisions;
  const sqlRequest = sqlParser.sqlify({
    ...defaultSelectAST,
    columns,
  });

  return parseSqlStringForIncisionsInFilter(sqlRequest);
};

export const parsePeriodFilterDisplayName = (value: string | null) => {
  const dateString = String(value);

  const processQuarter = (input: string) => (input.includes('квартал') ? input.replace(/квартал/g, 'кв.') : input);

  const dates = dateString.split(' — ');
  if (dates.length === 1 || dates[0] === dates[1]) {
    const title = processQuarter(dates[0]);
    return title;
  }

  const processedTitle = processQuarter(dateString);
  return processedTitle;
};

export const calculateGroupPositionConfig = (
  array: {
    positionConfig: BoardPositionConfigInterface;
  }[],
): BoardPositionConfigInterface => {
  let minX = Infinity;
  let minY = Infinity;
  let maxX = -Infinity;
  let maxY = -Infinity;

  array.forEach((item) => {
    const { x, y, width, height } = item.positionConfig;

    minX = Math.min(minX, x);
    minY = Math.min(minY, y);
    maxX = Math.max(maxX, x + width);
    maxY = Math.max(maxY, y + height);
  });

  const groupWidth = maxX - minX;
  const groupHeight = maxY - minY;

  return {
    ...initialPositionConfig,
    x: minX,
    y: minY,
    width: groupWidth,
    height: groupHeight,
  };
};

export const calculatePositionConfigForWidgetInsideGroup = (
  positionConfig: BoardPositionConfigInterface,
  groupPositionConfig: BoardPositionConfigInterface,
  isGrouped: boolean,
) => {
  const { x, y, width, height } = positionConfig;

  return {
    ...positionConfig,
    x: isGrouped ? x - groupPositionConfig.x : x + groupPositionConfig.x,
    y: isGrouped ? y - groupPositionConfig.y : y + groupPositionConfig.y,
    width,
    height,
  };
};

export const useClickHandler = (singleClickHandler: NoopType, doubleClickHandler: NoopType, delay = 250) => {
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  return (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }

    if (event.detail === 1) {
      timerRef.current = setTimeout(() => {
        singleClickHandler();
      }, delay);
    } else if (event.detail === 2) {
      doubleClickHandler();
    }
  };
};

export const getHls = (hls: HlsColorInterface) =>
  hsl(Math.floor(hls.h), Math.floor(hls.s) / 100, Math.floor(hls.l) / 100).formatHex() +
  Math.round((hls.opacity || 1) * 2.55)
    .toString(16)
    .padStart(2, '0');

export const mouseTrackerByClass = ({
  className,
  onDragCallback,
  customInitialPositionConfig,
}: {
  className: string;
  onDragCallback: (position: BoardPositionConfigInterface) => void;
  customInitialPositionConfig?: BoardPositionConfigInterface;
}) => {
  const element = document.querySelector(`.${className}`) as HTMLElement;
  const defaultPositionConfig = customInitialPositionConfig || initialPositionConfig;

  const positionConfig = { ...defaultPositionConfig };

  if (!element) return onDragCallback(positionConfig);

  const { createArea, updateArea, removeArea } = createSelectionArea(element);
  const { setCustomCursor, resetCursor } = createCursorChanger(element, 'crosshair');
  setCustomCursor();

  let startX = 0;
  let startY = 0;
  let isDragging = false;

  const handleMouseMove = (e: MouseEvent) => {
    if (!element) return;

    isDragging = true;

    const { scrollTop, scrollLeft } = element;

    const rect = element.getBoundingClientRect();
    const currentX = e.clientX - rect.left;
    const currentY = e.clientY - rect.top;

    const x = Math.min(startX, currentX);
    const y = Math.min(startY, currentY);
    const width = Math.abs(currentX - startX);
    const height = Math.abs(currentY - startY);

    updateArea(x, y, width, height);

    positionConfig.x = x + scrollLeft;
    positionConfig.y = y + scrollTop;
    positionConfig.width = width < MIN_SIZE_VISUALISATION ? defaultPositionConfig.width : width;
    positionConfig.height = height < MIN_SIZE_VISUALISATION ? defaultPositionConfig.height : height;
  };

  const handleMouseUp = () => {
    window.removeEventListener('mousemove', handleMouseMove);
    window.removeEventListener('mouseup', handleMouseUp);

    removeArea();
    resetCursor();

    if (isDragging) {
      onDragCallback(positionConfig);
    } else {
      onDragCallback({
        ...defaultPositionConfig,
        x: positionConfig.x,
        y: positionConfig.y,
      });
    }

    window.removeEventListener('mousedown', handleMouseDown);
  };

  const handleMouseDown = (e: MouseEvent) => {
    if (!element) {
      onDragCallback(positionConfig);
      window.removeEventListener('mousedown', handleMouseDown);
      return;
    }

    createArea(startX, startY);
    const rect = element.getBoundingClientRect();
    const isInElement = e.clientX >= rect.left && e.clientX <= rect.right && e.clientY >= rect.top && e.clientY <= rect.bottom;

    if (isInElement) {
      startX = e.clientX - rect.left;
      startY = e.clientY - rect.top;
      isDragging = false;

      positionConfig.x = e.clientX - rect.left;
      positionConfig.y = e.clientY - rect.top;

      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    } else {
      onDragCallback(positionConfig);
      resetCursor();
      window.removeEventListener('mousedown', handleMouseDown);
    }
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('mousedown', handleMouseDown);
      removeArea();
      resetCursor();
      return;
    }
  };

  window.addEventListener('mousedown', handleMouseDown);
  window.addEventListener('keydown', handleKeyDown);

  return () => {
    window.removeEventListener('mousedown', handleMouseDown);
    window.removeEventListener('mousemove', handleMouseMove);
    window.removeEventListener('mouseup', handleMouseUp);
    window.removeEventListener('keydown', handleKeyDown);
  };
};

export const createCursorChanger = (element: HTMLElement, cursorType: CursorType) => {
  const originalCursor = window.getComputedStyle(element).cursor;

  const setCustomCursor = () => {
    element.style.cursor = `${cursorType}`;
    element.querySelectorAll('*').forEach((child) => {
      (child as HTMLElement).style.cursor = `${cursorType}`;
    });
  };

  const resetCursor = () => {
    element.style.cursor = originalCursor;
    element.querySelectorAll('*').forEach((child) => {
      (child as HTMLElement).style.cursor = '';
    });
  };

  return {
    setCustomCursor,
    resetCursor,
  };
};

export const createSelectionArea = (element: HTMLElement) => {
  let selectionDiv: HTMLDivElement | null = null;

  const createArea = (x: number, y: number) => {
    document.body.classList.add('no-select');
    selectionDiv = document.createElement('div');
    selectionDiv.classList.add('selection-area');
    element.appendChild(selectionDiv);

    Object.assign(selectionDiv.style, {
      position: 'absolute',
      left: `${x}px`,
      top: `${y}px`,
      border: `1px solid var(${ColorVarsEnum.Accent})`,
      pointerEvents: 'none',
      zIndex: '9999',
    });
  };

  const updateArea = (x: number, y: number, width: number, height: number) => {
    if (selectionDiv) {
      selectionDiv.style.left = `${x}px`;
      selectionDiv.style.top = `${y}px`;
      selectionDiv.style.width = `${width}px`;
      selectionDiv.style.height = `${height}px`;
    }
  };

  const removeArea = () => {
    if (selectionDiv) {
      element.removeChild(selectionDiv);
      selectionDiv = null;
    }
    document.body.classList.remove('no-select');
  };

  return {
    createArea,
    updateArea,
    removeArea,
  };
};

export const transformationFileForUpload = ({ name, file }: { name: string; file: File }): FormData => {
  if (!file) {
    throw new Error('Файл отсутствует');
  }

  const newFormData = new FormData();
  newFormData.append(name, file);

  return newFormData;
};

export const transformFileListToFormData = (name: string, fileList: FileList): FormData => {
  if (!fileList || fileList.length === 0) {
    throw new Error('Список файлов пуст или не определен');
  }

  const formData = new FormData();

  Array.from(fileList).forEach((file) => {
    formData.append(name, file);
  });

  return formData;
};

export const loadAllVariants = (fontFamily: string, variants: VariantsFontsProjectSettingsInterface[]): Promise<void[]> => {
  const loadPromises = variants.map((variant) => {
    const fontStyle = variant.style || 'normal';
    const fontWeight = variant.weight || 'normal';
    const fontSize = '16px';
    let fontFamilyName = fontFamily;

    if (fontFamily.includes(' ')) {
      fontFamilyName = `"${fontFamily}"`;
    }

    const fontDescriptor = `${fontStyle} ${fontWeight} ${fontSize} ${fontFamilyName}`;

    return document.fonts.load(fontDescriptor).then((loadedFonts) => {
      if (loadedFonts.length > 0) {
        return Promise.resolve();
      } else {
        // Шрифт не загружен, загружаем вручную
        const srcParts = [];

        if (variant.urlWOFF) {
          srcParts.push(`url(${variant.urlWOFF}) format('woff')`);
        }
        if (variant.urlTTF) {
          srcParts.push(`url(${variant.urlTTF}) format('truetype')`);
        }

        if (srcParts.length === 0) {
          console.warn('Нет доступных URL для варианта:', variant);
          return Promise.resolve();
        }

        const src = srcParts.join(', ');

        const fontFace = new FontFace(fontFamily, src, {
          weight: variant.weight,
          style: variant.style,
          display: 'swap',
        });

        return fontFace
          .load()
          .then((loadedFontFace) => {
            document.fonts.add(loadedFontFace);
          })
          .catch((error) => {
            console.error('Не удалось загрузить вариант шрифта:', variant, error);
          });
      }
    });
  });

  return Promise.all(loadPromises);
};

const fontRules: Record<string, string[]> = {};

export const addFontFaceRules = (fontFamily: string, variants: VariantsFontsProjectSettingsInterface[]) => {
  const styleElementId = `font-face-${fontFamily}`;
  let styleElement = document.getElementById(styleElementId) as HTMLStyleElement;

  if (!styleElement) {
    styleElement = document.createElement('style');
    styleElement.id = styleElementId;
    document.head.appendChild(styleElement);
  }

  if (!fontRules[fontFamily]) {
    fontRules[fontFamily] = [];
  }

  variants.forEach((variant) => {
    const rule = `
      @font-face {
        font-family: '${fontFamily}';
        font-style: ${variant.style};
        font-weight: ${variant.weight};
        src: url('${variant?.urlTTF}') format('truetype'), url('${variant?.urlWOFF}'') format(opentype);
        font-display: swap;
      }
    `;
    if (!fontRules[fontFamily].includes(rule)) {
      fontRules[fontFamily].push(rule);
    }
  });

  styleElement.innerHTML = fontRules[fontFamily].join('\n');
};

export const unloadVariant = (fontFamily: string, variant: VariantsFontsProjectSettingsInterface): void => {
  const { style = 'normal', weight = 'normal' } = variant;

  // Удалить конкретный FontFace из document.fonts
  document.fonts.forEach((font) => {
    if (font.family === fontFamily && font.style === style && font.weight === weight) {
      document.fonts.delete(font);
      console.info(`Удален шрифт: ${fontFamily}, стиль: ${style}, вес: ${weight}`);
    }
  });

  // Если есть <style> элемент, обновляем его содержимое
  const styleElementId = `font-face-${fontFamily}`;
  const styleElement = document.getElementById(styleElementId) as HTMLStyleElement;

  if (styleElement) {
    const rules = styleElement.innerHTML.split('\n').filter((rule) => {
      return (
        !rule.includes(`font-family: '${fontFamily}'`) ||
        !rule.includes(`font-style: ${style}`) ||
        !rule.includes(`font-weight: ${weight}`)
      );
    });
    styleElement.innerHTML = rules.join('\n');
  }
};

export const mapValuesToColors = (backgroundConditionValue: string[], backgroundSettings?: BackgroundSettingsInterface) => {
  const colorArray: ColorValuesByThemeType[] = [];
  const colors = backgroundSettings?.colorSettings.backgroundColorBy.byCondition.colors;

  backgroundConditionValue.forEach((condition) => {
    const conditionIndex = parseInt(condition) - 1;
    const color = colors?.[conditionIndex].value;

    if (color) {
      colorArray.push(color);
    }
  });

  return colorArray;
};

export const convertHexToHslValues = (hex: string): convertHexToHslResultInteface => {
  let h = 0,
    s = 0,
    l = 0,
    opacity = 0;

  const hlsColor = hsl(hex);

  if (isNaN(hlsColor.h) && isNaN(hlsColor.s) && isNaN(hlsColor.l) && isNaN(hlsColor.opacity)) {
    return {
      success: false,
      message: 'Не валидный hex-код! Используйте модель HSLA',
      result: {
        h: 0,
        s: 0,
        l: 0,
        opacity: 100,
        hex: '#000000',
      },
    };
  }

  if (!isNaN(hlsColor.h)) h = hlsColor.h;
  if (!isNaN(hlsColor.s)) s = hlsColor.s * 100;
  if (!isNaN(hlsColor.l)) l = hlsColor.l * 100;
  if (!isNaN(hlsColor.opacity)) opacity = hlsColor.opacity * 100;

  return {
    success: true,
    result: {
      h: Math.floor(h || 0),
      s: Math.floor(s || 0),
      l: Math.floor(l || 0),
      opacity: Math.floor(opacity || 100),
      hex,
    },
  };
};

export const validateMoveRule = (currentIndex: number, direction: MoveToEnum, length: number): boolean => {
  if (currentIndex === -1) return false;
  if (direction === 'up' && currentIndex === 0) return false;
  if (direction === 'down' && currentIndex === length - 1) return false;
  return true;
};

const DIRECTION_CONFIG = {
  '1': {
    invalidDirection: -1,
    message: 'Значение пошло вниз, хотя массив до этого шёл на возрастание',
  },
  '-1': {
    invalidDirection: 1,
    message: 'Значение пошло вверх, хотя массив до этого шёл на убывание',
  },
  PERCENT: {
    range: [0, 100],
    message: 'Указанное число должно быть в пределах от 0 до 100.',
  },
};

/**
getInvalidIndicesWithErrors проверяет, является ли массив полностью возрастающим или полностью убывающим.
Если массив "ломает" уже выбранное направление (возрастание/убывание),
собирает индексы таких "ломающих" элементов и возвращает с пояснением.
*/
export const getInvalidIndicesWithErrors = (
  colors: SpecificColorByItem[] | null,
  direction: SortOrderEnum,
  valueType: ElementSpecificValueEnum,
) => {
  if (!colors) return {};

  const newErrors: Record<number, string> = {};
  let lastValue = direction === SortOrderEnum.ASCENDING ? -Infinity : Infinity;
  const directionFactor = direction === SortOrderEnum.ASCENDING ? 1 : -1;

  colors.forEach(({ numericalValue }, index) => {
    if (numericalValue == null) return;

    const numValue = Number(numericalValue);

    // Проверка диапазона для процентного режима
    if (valueType === ElementSpecificValueEnum.PERCENT) {
      const [min, max] = DIRECTION_CONFIG.PERCENT.range;
      if (numValue < min || numValue > max) {
        newErrors[index] = DIRECTION_CONFIG.PERCENT.message;
      }
    }

    // Проверка на нарушение порядка
    if (
      (numValue - lastValue) * directionFactor < 0 &&
      !newErrors[index] // Избегаем перезаписи ошибок
    ) {
      newErrors[index] = DIRECTION_CONFIG[directionFactor].message;
    }

    lastValue = numValue;
  });

  return newErrors;
};

/**
 * moveItemInArray вспомогательная функция: перемещает элемент массива с index = fromIndex на index = toIndex.
 * Возвращает новый массив (не мутирует исходный).
 */
export const moveItemInArray = <T>(array: T[], fromIndex: number, toIndex: number): T[] => {
  const newArray = [...array];
  [newArray[fromIndex], newArray[toIndex]] = [newArray[toIndex], newArray[fromIndex]];
  return newArray;
};

export const getOptionsByFieldType = (fieldType: string, operationType: string | null, hasExpression: boolean) => {
  let ruleOptions: {
    name: string;
    value: string;
    type: ColorOperatorEnum;
  }[] = [];
  let operationOptions = MOCK_GRAPHIC_OPERATION_TYPE_RULES;

  if (fieldType.includes('Date')) {
    ruleOptions = DATE_OPERATION_TYPES;
    operationOptions = MOCK_GRAPHIC_OPERATION_TYPE_RULES.filter((op) => op.type.includes('date'));
  } else if (fieldType.includes('String')) {
    ruleOptions = STRING_OPERATION_TYPES;
    operationOptions = MOCK_GRAPHIC_OPERATION_TYPE_RULES.filter((op) => op.type.includes('string'));
  } else if (fieldType.includes('Int') || fieldType.includes('Float') || fieldType.includes('Decimal')) {
    ruleOptions = NUMBER_OPERATION_TYPES;
    operationOptions = MOCK_GRAPHIC_OPERATION_TYPE_RULES.filter((op) => op.type.includes('number'));
  }

  if (hasExpression) {
    operationOptions = MOCK_GRAPHIC_OPERATION_TYPE_RULES.filter((op) => op.value === 'other');
  }

  if (operationType === VisualisationOperationTypesEnum.COUNT || operationType === VisualisationOperationTypesEnum.COUNTUNIQUE) {
    ruleOptions = NUMBER_OPERATION_TYPES;
  }

  return { ruleOptions, operationOptions };
};

export const createSqlStringFromRules = (rules: ColorByRuleInterface[]) => {
  const conditions = rules
    .filter((rule) => rule.isActive)
    .map((rule, index) => {
      const field = rule.customRequest || rule.fieldName;

      let fieldExpression = field;
      switch (rule.operationType) {
        case OperationTypeEnum.TEXT:
          fieldExpression = field;
          break;
        case OperationTypeEnum.SUM:
          fieldExpression = `SUM(${field})`;
          break;
        case OperationTypeEnum.AVG:
          fieldExpression = `AVG(${field})`;
          break;
        case OperationTypeEnum.MIN:
          fieldExpression = `MIN(${field})`;
          break;
        case OperationTypeEnum.MAX:
          fieldExpression = `MAX(${field})`;
          break;
        case OperationTypeEnum.COUNT:
          fieldExpression = `COUNT(${field})`;
          break;
        case OperationTypeEnum.COUNT_UNIQUE:
          fieldExpression = `COUNT(DISTINCT ${field})`;
          break;
        default:
          break;
      }

      let condition = '';
      switch (rule.rule) {
        case RuleEnum.TEXT_CONTAINS:
          condition = `WHEN ilike(${fieldExpression}, '%${rule.firstValue}%') THEN '${index}'`;
          break;
        case RuleEnum.TEXT_NOT_CONTAINS:
          condition = `WHEN not ilike(${fieldExpression}, '%${rule.firstValue}%') THEN '${index}'`;
          break;
        case RuleEnum.TEXT_STARTS_WITH:
          condition = `WHEN ilike(${fieldExpression}, '${rule.firstValue}%') THEN '${index}'`;
          break;
        case RuleEnum.TEXT_ENDS_WITH:
          condition = `WHEN ilike(${fieldExpression}, '%${rule.firstValue}') THEN '${index}'`;
          break;
        case RuleEnum.TEXT_EXACT:
          condition = `WHEN ${fieldExpression} = '${rule.firstValue}' THEN '${index}'`;
          break;
        case RuleEnum.GREATER_THAN:
          condition = `WHEN ${fieldExpression} > ${rule.firstValue} THEN '${index}'`;
          break;
        case RuleEnum.LESS_THAN:
          condition = `WHEN ${fieldExpression} < ${rule.firstValue} THEN '${index}'`;
          break;
        case RuleEnum.GREATER_OR_EQUAL:
          condition = `WHEN ${fieldExpression} >= ${rule.firstValue} THEN '${index}'`;
          break;
        case RuleEnum.LESS_OR_EQUAL:
          condition = `WHEN ${fieldExpression} <= ${rule.firstValue} THEN '${index}'`;
          break;
        case RuleEnum.EQUAL:
          condition = `WHEN ${fieldExpression} = ${rule.firstValue} THEN '${index}'`;
          break;
        case RuleEnum.NOT_EQUAL:
          condition = `WHEN ${fieldExpression} != ${rule.firstValue} THEN '${index}'`;
          break;
        case RuleEnum.BETWEEN:
          condition = `WHEN ${fieldExpression} BETWEEN ${rule.firstValue} AND ${rule.secondValue} THEN '${index}'`;
          break;
        case RuleEnum.NOT_BETWEEN:
          condition = `WHEN ${fieldExpression} NOT BETWEEN ${rule.firstValue} AND ${rule.secondValue} THEN '${index}'`;
          break;
        case RuleEnum.DATE_EXACT:
          condition = `WHEN ${fieldExpression} = '${rule.firstValue}' THEN '${index}'`;
          break;
        case RuleEnum.DATE_BEFORE:
          condition = `WHEN ${fieldExpression} <= '${rule.firstValue}' THEN '${index}'`;
          break;
        case RuleEnum.DATE_AFTER:
          condition = `WHEN ${fieldExpression} >= '${rule.firstValue}' THEN '${index}'`;
          break;
        case RuleEnum.DATE_BETWEEN:
          condition = `WHEN ${fieldExpression} BETWEEN '${rule.firstValue}' AND '${rule.secondValue}' THEN '${index}'`;
          break;
        case RuleEnum.DATE_NOT_BETWEEN:
          condition = `WHEN ${fieldExpression} NOT BETWEEN '${rule.firstValue}' AND '${rule.secondValue}' THEN '${index}'`;
          break;
        default:
          return '';
      }
      return condition;
    })
    .filter((condition) => condition)
    .join(' ');

  const sqlCaseString = conditions.length ? `CASE ${conditions} ELSE 'defaultColor' END` : '';

  return sqlCaseString;
};

export const filterColumns = ({
  columnsArray,
  activeAliasInColumns,
}: {
  columnsArray: (AST.BasicColumn | Column | AST.BasicFunctionColumn | AST.ValueCondition)[];
  activeAliasInColumns: (string | null)[];
}) => {
  return columnsArray.filter((column) => {
    if (isNull(column.as)) return false;

    const { nameIncision } = parseCustomAliasName(column.as);

    return activeAliasInColumns.includes(column.as) || activeAliasInColumns.includes(nameIncision);
  });
};

export const getFileType = (file: File): string => {
  if (!file) {
    throw new Error('File is required');
  }

  if (file.type) {
    return file.type;
  }

  const mimeTypes: Record<string, string> = {
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    gif: 'image/gif',
    pdf: 'application/pdf',
    txt: 'text/plain',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ttf: 'font/ttf',
    woff: 'font/woff',
    woff2: 'font/woff2',
  };

  const extension = file.name.split('.').pop()?.toLowerCase();

  if (extension && mimeTypes[extension]) {
    return mimeTypes[extension];
  }

  return 'Unsupported or missing file type';
};

export const normalizeHexColor = (rawValue: string) => {
  const trimmed = rawValue.trim();
  return trimmed.startsWith('#') ? trimmed : `#${trimmed}`;
};

export const formatNumberWithSpaces = (value: number | string) => {
  return value.toString().replace(/(?<=\d)(?=(\d{3})+$)/g, ' ');
};

export const getPositionConfigForPasteInCenterBlock = ({
  classNameBlock = trackerSection,
  initialPosition,
  groupPositionConfig,
}: {
  classNameBlock?: string;
  initialPosition: BoardPositionConfigInterface;
  groupPositionConfig?: BoardPositionConfigInterface;
}): BoardPositionConfigInterface => {
  const element = document.querySelector(`.${classNameBlock}`) as HTMLElement; // trackerSection
  const positionInGroup =
    groupPositionConfig && calculatePositionConfigForWidgetInsideGroup(initialPosition, groupPositionConfig, true);

  const { scrollTop, scrollLeft } = element;
  const { height, width } = element.getBoundingClientRect();

  const centerX = Math.floor(scrollLeft + width / 2);
  const centerY = Math.floor(scrollTop + height / 2);

  const groupDifferenceX = groupPositionConfig && Math.floor(centerX - groupPositionConfig.width / 2);
  const groupDifferenceY = groupPositionConfig && Math.floor(centerY - groupPositionConfig.height / 2);
  const coordinateX =
    positionInGroup && groupDifferenceX ? groupDifferenceX + positionInGroup.x : centerX - initialPosition.width / 2;
  const coordinateY =
    positionInGroup && groupDifferenceY ? groupDifferenceY + positionInGroup.y : centerY - initialPosition.height / 2;

  return {
    ...initialPosition,
    x: coordinateX,
    y: coordinateY,
  };
};

export const calculateCenter = (visuals: BoardPositionConfigInterface[]) => {
  const total = visuals.reduce(
    (acc, { x, y, width, height }) => {
      acc.sumX += x + width / 2; // Центр по x
      acc.sumY += y + height / 2; // Центр по y
      return acc;
    },
    { sumX: 0, sumY: 0 },
  );

  const centerX = total.sumX / visuals.length;
  const centerY = total.sumY / visuals.length;

  return { centerX, centerY };
};

export const recalcPercentsAutomatic = (
  colors: SpecificColorByItem[],
  valueType: ElementSpecificValueEnum,
): SpecificColorByItem[] => {
  const len = colors.length;

  if (len < 2) {
    return colors.map((color) => ({
      ...color,
      percent: '0',
      type: 'min',
    }));
  }

  return colors.map((color, index) => {
    if (index === 0) {
      return {
        ...color,
        percent: '0',
        type: 'min',
      };
    } else if (index === len - 1) {
      return {
        ...color,
        percent: '100',
        type: 'max',
      };
    } else {
      const computedPercent = Math.round((index / (len - 1)) * 100).toString();
      return {
        ...color,
        ...(valueType === ElementSpecificValueEnum.PERCENT ? { numericalValue: Number(computedPercent) } : {}),
        percent: computedPercent,
      };
    }
  });
};

export const cutNumberSecond = (num: number): number => Math.floor(num * 100) / 100;

export const getPlaceholderValue = ({
  isAutomatic,
  isFirst,
  isLast,
  percent,
  valueType,
  globalMinMax,
}: PlaceholderOptions): string => {
  let placeholder = '';

  if (isAutomatic) {
    placeholder = `${formatNumberWithSpaces(percent)}%`;
    if (isFirst) placeholder += ' (мин)';
    if (isLast) placeholder += ' (макс)';
  } else {
    if (valueType === ElementSpecificValueEnum.PERCENT) {
      placeholder = `${formatNumberWithSpaces(percent)}%`;
      if (isFirst) placeholder += ' (мин)';
      if (isLast) placeholder += ' (макс)';
    } else {
      if (isFirst) {
        placeholder = `${formatNumberWithSpaces(cutNumberSecond(globalMinMax.min))} (мин)`;
      } else if (isLast) {
        placeholder = `${formatNumberWithSpaces(cutNumberSecond(globalMinMax.max))} (макс)`;
      }
    }
  }

  return placeholder;
};
