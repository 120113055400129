import { css } from '@emotion/react';
import { reboot } from './reboot';
import { SELECTOR_MENU } from 'modules/workspace/constans';
import { colorPickerClassName } from 'modules/settingsContainer/ColorPicker/constants';
import { imagePickerClassName } from 'modules/settingsContainer/ImagePicker/constants';
import { echartsTooltipClassName } from 'constants/echarts';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import styled from 'styled-components';

export const globalStyles = css`
  .projectAll {
    font-family: var(--font-family);
    font-style: var(--font-weight);
    font-weight: var(--font-style);
  }

  .project {
    --font-family: var(--font-family);
    --font-weight: var(--font-weight);
    --font-style: var(--font-style);
  }

  /* Уровень визуализации */

  /* Уровень элемента */
  .element {
    --font-family: var(--font-family);
    --font-weight: var(--font-weight);
    --font-style: var(--font-style);
  }

  /* Применение шрифтов к текстовым элементам */
  .text {
    font-family: var(--font-family);
    font-weight: var(--font-weight);
    font-style: var(--font-style);
  }

  ${reboot};

  /* Системный уровень */

  body {
    overflow: hidden;
  }

  .SnackbarContainer-root {
    z-index: 2000 !important;
  }

  .${SELECTOR_MENU} {
    /* 1301 - more than MuiModal-root z-index = 1300 (from MUI) */
    /* for Autocomplete */

    &.MuiAutocomplete-popper {
      z-index: 1301;
    }

    /* for Selector */

    .MuiPaper-root {
      z-index: 1301;
    }
  }

  .${colorPickerClassName} {
    .MuiPaper-root {
      /* for ColorPicker */
      z-index: 1301;
    }
  }

  .${imagePickerClassName} {
    .MuiPaper-root {
      /* for ColorPicker */
      z-index: 1301;
    }
  }

  html {
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
  }

  .ace_editor.ace_autocomplete {
    width: 500px;
  }

  .${echartsTooltipClassName} {
    display: none;
    background-color: var(${ColorVarsEnum.Level_3_menu}) !important;
    border-radius: 2px !important;
    border: 1px solid var(${ColorVarsEnum.Level_4}) !important;
    padding: 14px 12px !important;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15) !important;
    color: var(${ColorVarsEnum.Level_2}) !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
  }

  .MuiPopover-root {
    position: unset !important;
  }

  .MuiPaper-root {
    //position: unset !important;

    .MuiAutocomplete-noOptions {
      background-color: ${`var(${ColorVarsEnum.Level_3_menu})`} !important;
      color: ${`var(${ColorVarsEnum.Level_1})`};
      height: 30px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    li {
      width: 100%;
      color: ${`var(${ColorVarsEnum.Level_1})`};
      transition: all 200ms;
      padding: 8px !important;
      height: 30px;
      background: ${`var(${ColorVarsEnum.Alert})`};
      text-overflow: ellipsis;
      white-space: nowrap;

      div,
      span {
        z-index: 2;
      }
    }

    li:hover {
      background-color: ${`var(${ColorVarsEnum.Level_3_menu})`};

      :before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: ${`var(${ColorVarsEnum.Level_5})`};
        z-index: 1;
      }
    }

    ul {
      cursor: pointer;
      padding: 0;
      max-height: 300px;

      .Mui-focused,
      .MuiAutocomplete-option[aria-selected='true'] {
        background-color: ${`var(${ColorVarsEnum.Level_3_menu})`} !important;
      }

      li {
        color: ${`var(${ColorVarsEnum.Level_1})`};
        transition: all 200ms;
        padding: 8px !important;
        height: 30px;
        background: ${`var(${ColorVarsEnum.Level_3_menu})`};
        text-overflow: ellipsis;
        white-space: nowrap;

        div,
        span {
          z-index: 2;
        }
      }

      li:hover {
        background-color: ${`var(${ColorVarsEnum.Level_3_menu})`};

        :before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: ${`var(${ColorVarsEnum.Level_5})`};
          z-index: 1;
        }
      }

      .Mui-selected {
        color: ${`var(${ColorVarsEnum.Accent})`};
      }
    }

    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      transform: rotate(90deg);
      top: 20px;
      right: 20px;
      position: absolute;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: ${`var(${ColorVarsEnum.Level_5})`};
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: ${`var(${ColorVarsEnum.Level_4})`};
    }
  }

  .MuiTooltip-popper {
    z-index: 9999 !important;
  }

  .SnackbarContent-root.SnackbarItem-variantError .SnackbarItem-message {
    padding: 8px 24px !important;
  }

  .SnackbarContent-root.SnackbarItem-variantSuccess .SnackbarItem-message {
    padding-right: 16px !important;
  }
`;

export const Container = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;
